import React from "react";
import {
  isNavbar,
  isBanner,
  blocksWithoutTabContent,
  isStickyBlock,
  isAnnouncementStrip,
} from "utils/renderBlocks";
import { RenderBlock } from "components/RenderBlock";
import { Container } from "components/Design/Grid/Grid";
import { HIGHLIGHT } from "store/constants/blockThemes";
import cx from "classnames";

const OptionallyWrapInsideContainer = ({ block, blocks, isLanding }) => {
  let wrap = true;
  let spaceY = true;

  switch (block.__typename) {
    case "Cms_ComponentPageRooms":
    case "ComponentPageRooms":
      wrap = block.roomTheme === "LISTING";
      break;
    case "Cms_ComponentPageContactUs":
    case "ComponentPageContactUs":
      wrap = spaceY = !["DEFAULT", "NAVBAR"].includes(block.theme);
      break;
    case "Cms_ComponentPageBookingForm":
    case "ComponentPageBookingForm":
      wrap = spaceY = false;
      break;
    case "Cms_ComponentPageTabs":
    case "ComponentPageTabs":
      wrap = block.tabsTheme !== HIGHLIGHT;
      break;
    case "Cms_ComponentPageCards":
    case "ComponentPageCards":
      wrap = !["SLOW_MOVING", "HIGHLIGHTS_CAROUSEL"].includes(block.cardsTheme);
      break;
    case "Cms_ComponentPageImageCard":
    case "ComponentPageImageCard":
      wrap = !["HIGHLIGHTS", "CLICKABLE"].includes(block.imageCardTheme);
      break;

    case "Cms_ComponentPageSeo":
    case "ComponentPageSeo":
      wrap = spaceY = false;
      break;
    default:
      break;
  }

  return (
    <div
      className={cx({
        "odd:bg-gradient-rooms even:bg-gradient-hospitality": isLanding,
        "py-20 lg:py-40": !isLanding && spaceY,
        "py-40 lg:py-60": isLanding && spaceY,
      })}>
      {wrap ? (
        <Container>
          <RenderBlock block={block} blocks={blocks} />
        </Container>
      ) : (
        <RenderBlock block={block} blocks={blocks} />
      )}
    </div>
  );
};

export const DefaultLayout = ({ blocks, isLanding }) => {
  return blocksWithoutTabContent(blocks).map((b) => {
    const skip =
      isNavbar(b) || isBanner(b) || isStickyBlock(b) || isAnnouncementStrip(b);
    if (!skip) {
      return (
        <OptionallyWrapInsideContainer
          isLanding={isLanding}
          key={`${b.__typename}:${b.id}`}
          block={b}
          blocks={blocks}
        />
      );
    }

    return null;
  });
};
