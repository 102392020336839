import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import cx from "classnames";
import {
  navbar,
  banner,
  stickyBlocks,
  contactUsCard,
  navBarContact,
  whatsAppContact,
  whatsAappUrlForPage,
  announcementStrip,
} from "utils/renderBlocks";
import FontLoad from "components/FontLoad";
import { GuestOptionsBar } from "components/GuestOptionsBar";
import { WhatsAppFloat } from "components/Design/FloatingButton";
import RoomSelectionSearchDrawer from "components/Design/Drawer/RoomSelectionSearchDrawer";
import { RenderBlock } from "components/RenderBlock";
import { FOOTER, NAVBAR } from "store/constants/blockThemes";
import { DefaultLayout } from "components/DefaultLayout";
import { StickyCta } from "components/StickyCta";
import { FooterBlock } from "components/Blocks/FooterBlock";
import "components/Layout/style.less";
import { transformObject } from "utils/transformv4";
import { getHotel } from "utils/getHotel";
import {
  AYANA_RESORT_ID,
  AYANA_RIMBA_JIMBARAN_ID,
  AYANA_SEGARA_ID,
  AYANA_VILLA_ID,
  AYANA_KOMODO_ID,
  AYANA_LAKODIA_ID,
} from "store/constants/hotel";
import { boolean } from "yup";

const DefaultPage = (props) => {
  const {
    allNavigationMenuJson: { nodes: navigations },
    cms,
    allSocialMediaJson: { nodes: allSocialMedia },
  } = props.data;

  const {
    hotelsByLocale,
    destinationImagesSections,
    supportDrawers,
    lvxLogo: [lvxLogo],
    hotelPropertyBali,
    page: { blocks },
  } = transformObject(cms);

  const { language } = useI18next();

  const navbarBlock = navbar(blocks);
  const bannerBlock = banner(blocks);
  const announcementStripBlock = announcementStrip(blocks);
  const stickyBlock = stickyBlocks(blocks);
  const contactUsBlock = contactUsCard(blocks);
  const whatsAppContactBlock = whatsAppContact(blocks);
  const destination = navbarBlock?.destination;
  const navbarContactBlock = navBarContact(blocks);
  const formattedWhatsAppUrl = whatsAappUrlForPage({
    hotel: getHotel(navbarBlock, hotelsByLocale),
    destination: navbarBlock.destination,
    whatsAppContactBlock,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const baliHotels = [
    AYANA_VILLA_ID,
    AYANA_SEGARA_ID,
    AYANA_RESORT_ID,
    AYANA_RIMBA_JIMBARAN_ID,
  ].map((hotelId) => hotelPropertyBali.find(({ id }) => hotelId === id));

  const statusWaDisbled = [AYANA_KOMODO_ID, AYANA_LAKODIA_ID].find(
    (hotelId) => hotelId === navbarBlock.hotel?.id
  );

  const hotel = getHotel(navbarBlock, hotelsByLocale);

  //privacy policy
  var forBlocks = blocks;
  if (props?.pageContext?.id === "1455" && language != "en") {
    forBlocks =
      props?.data?.cms?.page?.data?.attributes?.localizations?.data?.find(
        (x) => x.attributes.locale === language
      )?.attributes?.blocks;
  }

  return (
    <>
      {(language === "zh" || language === "ja") && <FontLoad />}

      <div
        className={cx("antialiased", {
          /* helps in wrapping words at spaces for Japanese language */
          "font-ja wrap-word": language === "ja",
          "font-zh": language === "zh",
          "font-ko": language === "ko",
        })}>
        <div className="hidden lg:block lg:sticky lg:top-0 lg:z-100">
          <GuestOptionsBar
            destinationCards={destinationImagesSections[0].images}
            hotel={navbarBlock.destination}
            cartCount={0}
            navigationHotel={navbarBlock.hotel?.name}
            hotelPropertyBali={baliHotels}
          />
        </div>
        <div className="antialiased">
          <RenderBlock
            key={`navbar-${navbarBlock.id}`}
            block={{
              ...navbarBlock,
              allHotels: hotelsByLocale,
              navigations,
              destinationImagesSections,
              supportDrawers,
              theme: NAVBAR,
              formattedWhatsAppUrl,
              navbarContactBlock,
              announcementStrip: announcementStripBlock,
            }}
          />
          {bannerBlock && (
            <RenderBlock
              key={`${bannerBlock.__typename}:${bannerBlock.id}`}
              block={bannerBlock}
            />
          )}
          <div className="overflow-x-hidden">
            <DefaultLayout
              blocks={forBlocks}
              isLanding={props.pageContext.isLanding}
            />
          </div>
          <FooterBlock
            {...{
              ...navbarBlock,
              allHotels: hotelsByLocale,
              allSocialMedia,
              navigations,
              destinationImagesSections,
              supportDrawers,
              theme: FOOTER,
              lvxLogo,
            }}
          />
          {!Boolean(statusWaDisbled) && (
            <WhatsAppFloat link={formattedWhatsAppUrl} />
          )}
          <RoomSelectionSearchDrawer
            location={destination}
            hotelCode={hotel.hotel_code}
            cta={(onClick, sticky) => (
              <div
                className={cx(
                  "fixed lg:hidden bottom-0 w-full z-20 transition-all duration-500",
                  {
                    "opacity-0 invisible": !sticky,
                    "opacity-100 visible": sticky,
                  }
                )}>
                <StickyCta
                  stickyBlock={stickyBlock}
                  whatsAppUrl={
                    Boolean(statusWaDisbled) ? "" : formattedWhatsAppUrl
                  }
                  contactUsBlock={contactUsBlock}
                  onClick={onClick}
                  destination={destination}
                />
              </div>
            )}
            hotelId={navbarBlock.hotel?.id}
          />
        </div>
      </div>
    </>
  );
};

export default DefaultPage;

export const query = graphql`
  query DefaultTemplate($id: ID!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    allNavigationMenuJson {
      ...LocalNavigation
    }

    allSocialMediaJson {
      nodes {
        hotel
        code
        socmed {
          link {
            en
            ja
          }
          iconName
        }
      }
    }

    cms {
      ...PageLayout
      page: pageByLocale(id: $id, language: $language) {
        data {
          id
          attributes {
            slug
            layout
            localizations {
              data {
                attributes {
                  locale
                  blocks {
                    __typename
                    ... on Cms_ComponentPageTitle {
                      id
                      title
                      titleTheme: theme
                    }
                    ... on Cms_ComponentPageText {
                      id
                      title
                      textTheme: theme
                      description
                      reference
                    }
                  }
                }
              }
            }
            ...CmsPageDynamicBlocks_v4
          }
        }
      }
    }
  }
`;
